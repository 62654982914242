import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "headline",
    "timeLimitMessage",
    "countdown",
    "hours",
    "minutes",
    "seconds",
    "toggle",
    "activation",
    "option",
  ];
  static values = {
    examTimeLimit: Number,
  };

  connect() {
    const timeLimit = this.examTimeLimitValue;
    const self = this;
    this.timeLimitMessageTarget.style.display = "none";
    if (timeLimit != -1) {
      (function () {
        const second = 1000,
          minute = second * 60,
          hour = minute * 60,
          day = hour * 24;

        const countDown = new Date().getTime() + timeLimit * 60 * 1000,
          x = setInterval(function () {
            const now = new Date().getTime(),
              distance = countDown - now;

            (self.hoursTarget.innerText = Math.floor((distance % day) / hour)),
              (self.minutesTarget.innerText = Math.floor(
                (distance % hour) / minute
              )),
              (self.secondsTarget.innerText = Math.floor(
                (distance % minute) / second
              ));
            if (distance < 0) {
              self.headlineTarget.style.display = "none";
              self.countdownTarget.style.display = "none";
              self.timeLimitMessageTarget.style.display = "block";
              clearInterval(x);
              setInterval(function () {
                window.location.href = "https://www.zamn.sa";
              }, 3000);
            }
          }, 0);
      })();
    } else {
      this.element.style.display = "none";
    }
  }

  toggleTargetConnected() {
    const checkbox = this.activationTarget;

    const inputElements = this.optionTargets;
    this.toggle(checkbox, inputElements);
    checkbox.addEventListener("change", () => {
      this.toggle(checkbox, inputElements);
    });
  }

  onTargetChange(e) {
    let targetID = e.target.value;
    let courseID = window.location.pathname.split("/")[3];
    get(`/school/courses/${courseID}/safe_exam_browser?target_id=${targetID}`, {
      responseKind: "turbo-stream",
    });
  }

  toggle(checkbox, inputs) {
    inputs.forEach((input) => {
      input.disabled = !checkbox.checked;
    });
  }
}
