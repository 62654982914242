import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

export default class extends Controller {
  static values = {
    title: String,
  };

  initialize() {
    this.element.innerHTML = `
      <div class="flex justify-center items-center p-6">
        <i class="fas fa-spinner fa-spin me-2"></i>
      </div>
    `;
  }

  async connect() {
    const response = await get(`/curriculum_rooms/`, {
      responseKind: "html",
    });
    if (response.ok) {
      const html = await response.html;
      this.element.innerHTML = html;
    }
  }

  updateUrlField(e) {
    // https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04
    var input = document.getElementById("link_to_complete");
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(input, e.target.value);
    var inputEvent = new Event("change", {bubbles: true});
    input.dispatchEvent(inputEvent);
  }
}
